import { PageMeta, PageProps } from "@/types";
import { Head, Link } from "@inertiajs/react";
import "../../css/landing.css";
import SiteLayout from "@/Layouts/SiteLayout";
import IntegrationCard from "@/Components/IntegrationCard";

export default function Home({
    meta,
    iconsCount,
}: PageProps<{
    meta: PageMeta;
    iconsCount: number;
}>) {
    const integrations = [
        {
            title: "VS Code",
            description: "InterlaceKit for VS Code",
            icon: "https://cdn.interlacekit.com/brands/vscode.svg",
            installUrl:
                "https://marketplace.visualstudio.com/items?itemName=interlacekit.interlacekit",
        },
        {
            title: "JetBrains IDEs",
            description: "InteliJ, WebStorm, etc.",
            icon: "https://cdn.interlacekit.com/brands/jetbrains.png",
            installUrl:
                "https://plugins.jetbrains.com/plugin/25846-interlacekit",
        },
        {
            title: "Figma",
            description: "SVG icons directly in Figma.",
            icon: "https://cdn.interlacekit.com/brands/figma.png",
            installUrl: "#",
        },
        {
            title: "Miro",
            description: "SVG icons directly in Miro.",
            icon: "https://cdn.interlacekit.com/brands/miro.jpeg",
            installUrl: "#",
        },
        {
            title: "Canva",
            description: "SVG icons directly in Canva.",
            icon: "https://cdn.interlacekit.com/brands/canva.jpeg",
            installUrl: "#",
        },
    ];

    return (
        <SiteLayout>
            <Head>
                <title>{meta.title}</title>
                <meta name="title" content={meta.title} />
                <meta name="description" content={meta.description} />
            </Head>

            <main className="mb-5">
                <div className="container py-3">
                    <section className="card border-0 bg-light mb-4 mb-md-5">
                        <div className="card-body p-4 p-md-5">
                            <div className="row align-items-center gy-4 gx-5">
                                <div className="col-lg-6">
                                    <div className="mb-1">
                                        <small>
                                            <span className="badge text-bg-dark text-uppercase">
                                                Just Launched
                                            </span>
                                        </small>
                                    </div>
                                    <h1 className="display-5 fw-semibold mb-3">
                                        All Open Source Icons, One Search Away
                                    </h1>
                                    <p className="lead mb-4">
                                        Search {iconsCount} icons from popular
                                        open source libraries in one place.{" "}
                                        <span className="fw-semibold text-decoration-underline">
                                            Find the perfect icon faster
                                        </span>
                                        , customize on the fly, and export in
                                        any format you need.
                                    </p>
                                    <Link
                                        href={route("pages.icons")}
                                        className="btn btn-warning btn-lg me-3"
                                    >
                                        Browse {iconsCount} Icons
                                    </Link>
                                </div>
                                <div className="col-lg-6">
                                    <img
                                        src="https://cdn.interlacekit.com/placeholder.jpg"
                                        alt="placeholder image"
                                        loading="lazy"
                                        className="img-fluid rounded-3"
                                    />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="mb-4 mb-md-5">
                        <h2 className="mb-3 fw-normal">Plugins</h2>
                        <div className="row g-3">
                            {integrations.map((integration, index) => (
                                <div className="col-md-6 col-xl-4" key={index}>
                                    <IntegrationCard {...integration} />
                                </div>
                            ))}
                        </div>
                    </section>

                    <section className="py-3">
                        <div className="row">
                            <div className="col-md-6">
                                <h2 id="faq" className="mb-3 fw-normal">
                                    Have any questions?
                                </h2>
                                <p>
                                    Contact me on{" "}
                                    <a
                                        href="https://x.com/_priyashpatil"
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        Twitter
                                    </a>{" "}
                                    or by{" "}
                                    <a href="mailto:hello@interlacekit.com">
                                        email
                                    </a>
                                    .
                                </p>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </section>
                </div>
            </main>
        </SiteLayout>
    );
}
