import ComingSoonBadge from "./ComingSoonBadge";

interface IntegrationCardProps {
    title: string;
    description: string;
    icon: string;
    installUrl: string;
}

export default function IntegrationCard({
    title,
    description,
    icon,
    installUrl,
}: IntegrationCardProps) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center gap-3">
                    <img
                        src={icon}
                        alt={`${title} Logo`}
                        loading="lazy"
                        height={40}
                        width="auto"
                    />
                    <div className="flex-grow-1">
                        <h3 className="fs-5 fw-normal mb-0 d-flex align-items-center gap-2">
                            {title}{" "}
                            {installUrl === "#" && (
                                <small>
                                    <ComingSoonBadge />
                                </small>
                            )}
                        </h3>
                        <div className="text-muted">{description}</div>
                    </div>

                    {installUrl !== "#" && (
                        <div className="text-end">
                            <a
                                className="btn btn-sm btn-outline-dark stretched-link"
                                href={installUrl}
                                rel="noopener"
                                target="_blank"
                            >
                                Install
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
